import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { Toast } from "./Interfaces";
import { FormDetails } from "./Interfaces";

import firebase from "firebase/compat/app";

export const isAuthenticatedAtom = atomWithStorage("isAuthenticated", false);
export const showSidebarAtom = atomWithStorage("showSidebar", false);
export const reduceSidebarAtom = atomWithStorage("reduceSidebar", false);

export const userDetails = atomWithStorage<{
  email: string;
  username: string;
  avatar: string;
  id: string;
}>("userDetails", {
  email: "",
  username: "",
  avatar: "",
  id: "",
});

export const toastState = atom<Toast>({
  severity: "info",
  color: "info",
  message: "This is a toast",
  open: false,
});

export const draftForm = atomWithStorage<FormDetails>("draftForm", {
  title: "",
  topic: "",
  sub_topic: "",
  answer_file_format: "",
  answer_length: 1,
  attachments: [],
  details: "",
  duration_to_answer_number: 1,
  duration_to_answer_string: "",
  pricing: 0,
  recommended_writer: {
    major: "",
    school: "",
  },
  summary: "",
  standard_writer_fee: 0,
});

export const activeFilter = atomWithStorage("activeFilter", "");
export const activeFilterStatus = atomWithStorage("activeFilterStatus", "");

export const activeChatQuestionId = atomWithStorage("activeChatQuestionId", "");
export const activeChatTutorUsername = atomWithStorage(
  "activeChatTutorUsername",
  ""
);
export const activeChatTutorAvatar = atomWithStorage(
  "activeChatTutorAvatar",
  ""
);
export const activeChatTutorId = atomWithStorage("activeChatTutorId", "");
export const activeChatDueDate =
  atomWithStorage<firebase.firestore.Timestamp | null>(
    "activeChatDueDate",
    null
  );

export const supportPhoneNumber = atom<string>("+1 (440) 536-3775");
export const supportPhoneNumberWithoutFormating = atom<number>(14405363775);
export const supportEmail = atom<string>("support@scholars.guide");
