import React from "react";

import Routes from "./Routes";

// Set the string key and the initial value

function App() {
  return <Routes />;
}

export default App;
