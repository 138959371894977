import React, { Suspense } from "react";
import { useAtom } from "jotai";
import { Routes, Route } from "react-router-dom";
import "./Routes.css";

import Container from "@mui/material/Container";

import {
  isAuthenticatedAtom,
  reduceSidebarAtom,
  showSidebarAtom,
} from "../Lib/State";

const LandingPage = React.lazy(() => import("../Pages/Landing"));
const Home = React.lazy(() => import("../Pages/Home"));
const Post = React.lazy(() => import("../Pages/Post"));
const Posts = React.lazy(() => import("../Pages/Posts"));
const Loading = React.lazy(() => import("../Pages/Loading"));
const NotFound = React.lazy(() => import("../Pages/NotFound"));
const PostDetails = React.lazy(() => import("../Pages/PostDetails"));
const DashboardNotFound = React.lazy(
  () => import("../Pages/NotFound-Dashboard")
);
const Profile = React.lazy(() => import("../Pages/PostDetails/Profile"));
const Account = React.lazy(() => import("../Pages/Account"));
const Chat = React.lazy(() => import("../Pages/Chat"));
const WriterProfile = React.lazy(() => import("../Pages/WriterProfile"));
const Support = React.lazy(() => import("../Pages/Support"));

const Signin = React.lazy(() => import("../Pages/Signin"));
const Signup = React.lazy(() => import("../Pages/Signup"));
const Username = React.lazy(() => import("../Pages/Username"));
const Email = React.lazy(() => import("../Pages/Email"));
const NewPassword = React.lazy(() => import("../Pages/NewPassword"));

const Library = React.lazy(() => import("../Pages/Library"));
const Tokens = React.lazy(() => import("../Pages/Tokens"));
const Sessions = React.lazy(() => import("../Pages/Sessions"));

const Nav = React.lazy(() => import("../Components/Nav"));
const Sidebar = React.lazy(() => import("../Components/Sidebar"));
const Toast = React.lazy(() => import("../Components/Toast"));

export default function RoutesPage() {
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [showSidebar, setShowSidebar] = useAtom(showSidebarAtom);
  const [reduceSidebar, setReduceSidebar] = useAtom(reduceSidebarAtom);

  return (
    <Suspense fallback={<Loading />}>
      {isAuthenticated ? (
        <div className={`grid-container`}>
          <nav className="gridHeader">
            <Nav
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
              reduceSidebar={reduceSidebar}
              setReduceSidebar={setReduceSidebar}
            />
          </nav>
          <aside
            className={`${showSidebar ? "active aside" : "aside"} ${
              reduceSidebar ? "reduceWidth" : ""
            } `}
          >
            <Sidebar
              setShowSidebar={setShowSidebar}
              reduceSidebar={reduceSidebar}
              setReduceSidebar={setReduceSidebar}
              showSidebar={showSidebar}
            />
          </aside>
          <main className="main">
            <Container
              maxWidth="xl"
              className="mainContentContainer"
              style={{ flex: 1 }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/questions" element={<Posts />} />
                <Route path="/questions/:id" element={<PostDetails />} />
                <Route
                  path="/questions/:id/:bid/:bidId/tutor-profile/:tutorId"
                  element={<Profile />}
                />
                <Route path="/post-question" element={<Post />} />
                <Route path="/account" element={<Account />} />
                <Route path="/talk-with-tutors" element={<Chat />} />
                <Route
                  path="/talk-with-tutors/view-profile/:tutorId"
                  element={<WriterProfile />}
                />
                <Route path="/support" element={<Support />} />

                <Route path="/library" element={<Library />} />
                <Route path="/tokens" element={<Tokens />} />
                <Route path="/sessions" element={<Sessions />} />

                <Route path="*" element={<DashboardNotFound />} />
              </Routes>
            </Container>
          </main>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/username" element={<Username />} />
          <Route path="/forgot-password/enter-email" element={<Email />} />
          <Route
            path="/forgot-password/enter-new-password"
            element={<NewPassword />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}

      <Toast />
    </Suspense>
  );
}
